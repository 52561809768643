<template>
  <a-modal v-model:visible="visible" :footer="null" :closable="false" :maskClosable="false">
    <a-result :title="options.title" :sub-title="options.subTitle" :status="options.status">
      <template v-if="!options.status" #icon>
        <loading-outlined />
      </template>
      <template #extra>
        <a-button v-if="options.copyText" v-on:click="copy" type="primary">Copy Link</a-button>
        <a-button v-if="options.txHash" v-on:click="redirect" type="primary"> View TX </a-button>
        <a-button :disabled="!options.status" v-on:click="close"> Close </a-button>
      </template>
    </a-result>
  </a-modal>
</template>

<script>
import { LoadingOutlined } from "@ant-design/icons-vue";
import { STORAGE_KEY_ADDRESS } from "@/constants/Constants";
import { CHAIN_CONFIG } from "@/constants/ChainConfig";
import { LocalStorageService } from "@/utils/utils";

export default {
  components: {
    LoadingOutlined,
  },
  data: function () {
    return {
      visible: true,
    };
  },
  created: async function () {},
  methods: {
    redirect: function () {
      console.log("redirect");
      const chainId = LocalStorageService.get(STORAGE_KEY_ADDRESS).chainId;
      const redirectUrl = CHAIN_CONFIG[chainId].blockExplorerUrls + "/tx/" + this.options.txHash;
      window.open(redirectUrl);
    },
    copy: async function () {
      await navigator.clipboard.writeText(this.options.copyText);
      this.$message.success("Share link copied");
    },
    close: function () {
      this.$emit("modalCloseEvent", true);
      this.visible = false;
    },
  },
  watch: {
    options() {},
  },
  props: ["options"],
  emits: ["modalCloseEvent"],
};
</script>
<style scoped></style>
