<template>
  <div v-if="remainMoneyFormatted">
    <p>HongBao ID: {{ envelopId }}</p>
    <p>Creator: {{ creator }}</p>
    <p>Token: {{ symbol }}</p>
    <p>Token Contract: {{ tokenAddr }}</p>
    <p>merkelRoot: {{ merkelRoot }}</p>
    <p>remainMoney: {{ remainMoneyFormatted }}</p>
    <p>remainCount: {{ remainCount }}</p>
    <p>expireTime: {{ expireTime }}</p>
  </div>
</template>

<script>
import { providerInstance } from "../utils/metamask.js";
import { formatEther } from "../utils/utils.js";

import { ERC20_ABI } from "@/constants/TokenABI";
import { ENVELOP_CONTRACT, ENVELOP_CONTRACT_ABI } from "@/constants/Contract";
import { TOKEN_CONFIG } from "@/constants/TokenConfig";
import { ethers } from "ethers";
export default {
  data: function () {
    return {
      creator: undefined,
      tokenAddr: undefined,
      merkelRoot: undefined,
      remainMoney: undefined,
      remainCount: undefined,
      expireTime: undefined,
      expireTimeDateObject: undefined,
      remainMoneyFormatted: undefined,
      decimals: undefined,
      symbol: undefined,
    };
  },
  created: async function () {
    this.getStatus();
  },
  methods: {
    getStatus: async function () {
      console.log("Get EnvelopInfo:", this.envelopId);
      if (this.envelopId == undefined) {
        return;
      }
      const network = await providerInstance.getNetwork();
      const contract = new ethers.Contract(ENVELOP_CONTRACT[network.chainId], ENVELOP_CONTRACT_ABI, providerInstance);
      const [creator, tokenAddr, merkelRoot, remainMoney, remainCount, expireTime] = await contract.info(
        this.envelopId
      );
      if (creator == ethers.constants.AddressZero) {
        console.log("Invalid HongBao ID: ", this.envelopId);
        return
      }
      this.creator = creator;
      this.tokenAddr = tokenAddr;
      this.merkelRoot = merkelRoot;
      this.remainMoney = remainMoney;
      this.remainCount = remainCount;
      this.expireTime = expireTime;
      let symbol = TOKEN_CONFIG[network.chainId][0].symbol;
      let decimals = 18;
      if (tokenAddr != ethers.constants.AddressZero) {
        const tokenContract = new ethers.Contract(tokenAddr, ERC20_ABI, providerInstance);
        symbol = await tokenContract.symbol();
        decimals = await tokenContract.decimals();
      }
      const wei = ethers.utils.formatUnits(remainMoney, "wei");
      this.symbol = symbol;
      this.remainMoneyFormatted = formatEther(wei, decimals);
      this.decimals = decimals;
      this.expireTimeDateObject = new Date(this.expireTime * 1000);

      const envelopInfo = {
        creator: this.creator,
        tokenAddr: this.tokenAddr,
        merkelRoot: this.merkelRoot,
        remainMoney: this.remainMoney,
        remainCount: this.remainCount,
        expireTime: this.expireTime,
        expireTimeDateObject: this.expireTimeDateObject,
        remainMoneyFormatted: this.remainMoneyFormatted,
        decimals: this.decimals,
        symbol: this.symbol,
      };
      this.$emit("envelopInfoEvent", envelopInfo);
    },
  },
  computed: {},
  watch: {
    envelopId() {
      this.getStatus();
    },
  },
  props: ["envelopId"],
  emits: ["envelopInfoEvent"],
};
</script>

<style scoped></style>
