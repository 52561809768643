const { ethers } = require("ethers");
const { sleep } = require("./utils.js");
import { CHAIN_CONFIG, SUPPORTED_CHAIN } from "@/constants/ChainConfig";

let provider = undefined;
if (window.ethereum) {
  handleEthereum();
} else {
  window.addEventListener('ethereum#initialized', handleEthereum, {
    once: true,
  });
  setTimeout(handleEthereum, 3000); // 3 seconds
}

function handleEthereum() {
  window.ethereum.on('chainChanged', (_chainId) => window.location.reload());
  provider = new ethers.providers.Web3Provider(window.ethereum, "any");
}

export const providerInstance = provider;

export async function switchNetwork(chainId) {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: ethers.utils.hexValue(chainId) }], // chainId must be in hexadecimal numbers
    });
  } catch (err) {
    console.log(err);
    if (err.code == 4902 || err.code == -32603) {
      if (SUPPORTED_CHAIN.includes(chainId)) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [CHAIN_CONFIG[chainId]],
          });
        } catch (err) {
          console.log(err);
          throw err;
        }
      }
    } else {
      throw err;
    }
  }
}
