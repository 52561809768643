<template>
  <a-layout class="layout">
    <a-layout-header>
      <a-row justify="space-between">
        <a-col>
          <envelop-header></envelop-header>
        </a-col>
        <a-col>
          <connect-wallet @metamaskEvent="onMetamaskEvent"></connect-wallet>
        </a-col>
      </a-row>
    </a-layout-header>
    <a-layout-content>
      <a-typography-title> Intro </a-typography-title>
      <a-typography-paragraph>
        HongBao Dapp is a traditional Chinese New Year custom in which red envelopes containing 💰 are given, signifying
        the transmission of luck & fortune. Crepto brings this oriental custom of good luck to the Web 3.0 world! Come
        and try using HongBao DApp, send cryptos to your friends on-chain!
      </a-typography-paragraph>
      <a-typography-paragraph>
        Put a few amount of cryptos in HongBao and share the HongBao OPEN link to your candidates. All candidates need
        open HongBao ASAP to get random amount of crypto. HongBao DApp is inspired by Wechat Red Packet, but it's
        decentralized one on blockchain.
      </a-typography-paragraph>
      <a-button type="primary" v-on:click="giftNow">Gift Now</a-button>
      <a-divider></a-divider>
      <a-typography-title :level="3">Multiple Chain</a-typography-title>
      <a-typography-paragraph>
        HongBao contract currently support
        <a href="https://etherscan.io/address/0x2a279559b0d5a4ba667fd1abfb0b0105f5c17141" target="_blank">Ethereum</a>,
        <a href="https://bscscan.com/address/0xC091fBCc12fb7326763dB395f1847D4bb0045EF6" target="_blank"
          >Binance Smart Chain</a
        >,
        <a href="https://polygonscan.com/address/0x759E689EC7Dd42097E40D1f5df558b130A7544a9" target="_blank"
          >Polygon Matice</a
        >, might support more chain in future.
      </a-typography-paragraph>
      <a-typography-title :level="3">All Crypto</a-typography-title>
      <a-typography-paragraph>
        Support ETH, BNB and Matic native chain coin, and all ERC-20 compatible token.
      </a-typography-paragraph>
      <a-typography-title :level="3">Discord Bot</a-typography-title>
      <a-typography-paragraph>
        Send HongBao to your friends directly in Discord! The FOMO, and the exhilaration of grabbing a random amount in
        a red packet will quickly get the dopamine pumping in your body, experience it once and you'll love the feeling!
      </a-typography-paragraph>
      <a-typography-paragraph>
        <a-button type="primary" v-on:click="addBot"> Add HongBao Bot</a-button>
      </a-typography-paragraph>
      <a-typography-title :level="3">Benefits</a-typography-title>
      <a-typography-paragraph>
        <ul>
          <li>Send a HongBao to your favorite project to reward the hard-working project team members.</li>
          <li>Sending a HongBao to your buddies to see who has the best luck.</li>
          <li>Uneven distribution of work, send a HongBao to whoever grabs the most amount to do it.</li>
          <li>
            Send an exclusive HongBao to your favorite person in Discord and let him/her feel exclusive preferential
            treatment!
          </li>
        </ul>
      </a-typography-paragraph>
      <a-typography-paragraph> More use scenarios, waiting for you to unlock! </a-typography-paragraph>
      <a-typography-title :level="3">Protocol Fee</a-typography-title>
      <a-typography-paragraph>
        HongBao charges 0.824% protocol fee, all profit will goes to
        <a href="https://etherscan.io/address/0x759e689ec7dd42097e40d1f5df558b130a7544a9" target="_blank"
          >Crepto Pass NFT contract</a
        >, which will be shared to Crepto Pass holder.
      </a-typography-paragraph>
    </a-layout-content>
  </a-layout>
  <a-layout-footer>
    <envelop-footer></envelop-footer>
  </a-layout-footer>
</template>

<script>
import EnvelopHeader from "@/components/EnvelopHeader.vue";
import EnvelopFooter from "@/components/EnvelopFooter.vue";
import ConnectWallet from "./ConnectWallet.vue";
export default {
  components: {
    ConnectWallet,
    EnvelopHeader,
    EnvelopFooter,
  },
  data: function () {
    return {
      network: undefined,
    };
  },
  created: async function () {},
  methods: {
    giftNow: function () {
      this.$router.push({ path: "/create" });
    },
    addBot: function () {
      window.open(
        "https://discord.com/api/oauth2/authorize?client_id=962986859619827722&permissions=3072&scope=bot applications.commands"
      );
    },
    onMetamaskEvent: function (metamaskInfo) {
      this.network = metamaskInfo.network;
    },
  },
  computed: {},
};
</script>

<style scoped>
div.ant-typography, .ant-typography p {
  font-size: 16px;
  margin-bottom: 1em;
}
</style>
