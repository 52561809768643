<template>
  <a-layout class="layout">
    <a-layout-header>
      <a-row justify="space-between">
        <a-col>
          <envelop-header></envelop-header>
        </a-col>
        <a-col>
          <connect-wallet @metamaskEvent="onMetamaskEvent"></connect-wallet>
        </a-col>
      </a-row>
    </a-layout-header>
    <a-layout-content>
      <envelop-tab selected="create" />
      <a-divider style="margin: 10px 0px" />

      <a-space direction="vertical" size="middle">
        <div>
          <a-row justify="left" style="font-size: 120%">
            <a-space>
              <a-col> Token </a-col>
              <a-col>
                <a-tooltip>
                  <template #title>Specify which type of Token in this HongBao</template>
                  <question-circle-outlined />
                </a-tooltip>
              </a-col>
            </a-space>
          </a-row>
          <a-row>
            <a-select class="row-select"
              v-model:value="selectedToken"
              size="large"
              :options="tokenSelectOptions"
              @change="doSwitchToken"
            ></a-select>
          </a-row>
          <a-row justify="space-between">
            <a-col>
              <a-space>
                <span :style="insufficientBalance ? 'color: red;' : ''"
                  >Balance: {{ displayBalance }} {{ selectedTokenSymbol }}</span
                >
                <span v-if="allowance" :style="allowance < amount ? 'color: red;' : ''"
                  >Allowance: {{ displayAllowance }}</span
                >
              </a-space>
            </a-col>
            <a-col>
              <a-typography-link v-on:click="onClickImportTokenButton">Import Token</a-typography-link>
            </a-col>
          </a-row>
        </div>
        <div>
          <a-row justify="left" style="font-size: 120%">
            <a-space>
              <a-col> Amount </a-col>
              <a-col>
                <a-tooltip>
                  <template #title>Specify how much token you want to put into this HongBao</template>
                  <question-circle-outlined />
                </a-tooltip>
              </a-col>
            </a-space>
          </a-row>
          <a-row>
            <a-input-number class="row-input" v-model:value="amount" placeholder="Input amount" size="large" />
          </a-row>
        </div>
        <div>
          <a-row justify="left" style="font-size: 120%">
            <a-space>
              <a-col :span="2"> Shares </a-col>
              <a-col>
                <a-tooltip>
                  <template #title>Specify how many people can open this HongBao</template>
                  <question-circle-outlined />
                </a-tooltip>
              </a-col>
            </a-space>
          </a-row>
          <a-row>
            <a-input-number class="row-input" v-model:value="shares" placeholder="Input shares" size="large" />
          </a-row>
        </div>
        <div>
          <a-row justify="left" style="font-size: 120%">
            <a-space>
              <a-col :span="2"> Public </a-col>
              <a-col>
                <a-tooltip>
                  <template #title
                    >Anyone can open this HongBao if choose YES, or only candidate wallet can open it</template
                  >
                  <question-circle-outlined />
                </a-tooltip>
              </a-col>
            </a-space>
          </a-row>
          <a-row>
            <a-radio-group v-model:value="publicChecked" :disabled="envelopConfig != undefined">
              <a-radio value="true">YES</a-radio>
              <a-radio value="false">NO</a-radio>
            </a-radio-group>
          </a-row>
          <div v-if="isPublicEnvelop == false">
            <a-textarea
              v-model:value="inputAddressList"
              placeholder="Input candidate wallet address, one address per line"
              :disabled="envelopConfig != undefined"
              :rows="10"
            />
          </div>
        </div>
        <div>
          <a-row v-if="canClick">
            <a-tooltip>
              <template #title>Contract will charge 0.824% of this HongBao</template>
              <question-circle-outlined />
            </a-tooltip>
            <span>Protocol fee: {{ protocalFee }} {{ selectedTokenSymbol }}</span>
          </a-row>
          <a-button class="row-button" type="primary" :disabled="!canClick" v-on:click="doClick">
            {{ buttonName }}
          </a-button>
        </div>
      </a-space>
    </a-layout-content>
    <a-layout-footer>
      <envelop-footer></envelop-footer>
    </a-layout-footer>
  </a-layout>
  <a-modal v-model:visible="switchNetworkModalVisible" title="Confirm switch network" @ok="switchMetamask">
    <p>You need switch chain network to proceed</p>
  </a-modal>
  <a-modal
    v-model:visible="importTokenModalVisable"
    title="Import a token"
    okText="Import"
    :ok-button-props="{
      disabled: disableImportTokenButton,
      onClick: onClickImport,
    }"
  >
    <a-input
      v-model:value="importTokenAddress"
      :onChange="onImportTokenAddressChanged"
      placeholder="Input token contract address"
    />
    <a-space v-if="importTokenName && importTokenSymbol" size="large">
      <span> Token name: {{ importTokenName }} </span>
      <span>Token symbol: {{ importTokenSymbol }}</span>
    </a-space>
  </a-modal>
  <template v-if="isLoading">
    <loading-modal :options="modalOptions" @modalCloseEvent="OnModalCloseEvent"> </loading-modal>
  </template>
</template>

<script>
import axios from "axios";
import { ethers } from "ethers";
import { FastBackwardFilled, QuestionCircleOutlined } from "@ant-design/icons-vue";
import { providerInstance, switchNetwork } from "@/utils/metamask";
import { UniqueId, formatEther, genMerkleRoot } from "@/utils/utils";
import ConnectWallet from "./ConnectWallet.vue";
import LoadingModal from "./LoadingModal.vue";
import EnvelopHeader from "@/components/EnvelopHeader.vue";
import EnvelopTab from "@/components/EnvelopTab.vue";
import EnvelopFooter from "@/components/EnvelopFooter.vue";
import { TOKEN_CONFIG } from "@/constants/TokenConfig";
import { CHAIN_CONFIG } from "@/constants/ChainConfig";
import { ERC20_ABI } from "@/constants/TokenABI";
import { ENVELOP_CONTRACT, ENVELOP_CONTRACT_ABI } from "@/constants/Contract";
function getValidAddressList(addressInput) {
  let addressList = [];
  addressInput.split("\n").forEach((item) => {
    item = item.replace(/\s+/g, "");
    if (ethers.utils.isAddress(item)) {
      addressList.push(item);
    }
  });
  return addressList;
}
export default {
  components: {
    QuestionCircleOutlined,
    ConnectWallet,
    LoadingModal,
    EnvelopHeader,
    EnvelopFooter,
    EnvelopTab,
  },
  data: function () {
    return {
      switchNetworkModalVisible: false,
      importTokenModalVisable: false,
      isLoading: false,
      importTokenAddress: undefined,
      importTokenName: undefined,
      importTokenSymbol: undefined,
      modalOptions: {},
      network: undefined,
      envelopConfig: undefined,
      metamaskAddress: undefined,
      tokenSelectOptions: undefined,
      selectedToken: undefined,
      amount: undefined,
      shares: undefined,
      publicChecked: "true",
      inputAddressList: undefined,
      addressList: [],
      balance: undefined,
      decimals: 18,
      allowance: undefined,
    };
  },
  created: async function () {
    const envelopConfigId = this.$route.query.envelopId;
    if (envelopConfigId) {
      const response = await axios.get('/api/list/candidate?envelopId=' + envelopConfigId);
      this.envelopConfig = response.data;
      this.inputAddressList = this.envelopConfig.walletList.join("\n");
      this.publicChecked = "false";
    }
  },
  mounted: async function () {},
  methods: {
    onImportTokenAddressChanged: async function () {
      console.log(this.importTokenAddress);
      try {
        this.importTokenAddress = ethers.utils.getAddress(this.importTokenAddress);
        const contract = new ethers.Contract(this.importTokenAddress, ERC20_ABI, providerInstance);
        this.importTokenName = await contract.name();
        this.importTokenSymbol = await contract.symbol();
      } catch (err) {
        this.$message.error("Check the token contract address");
      }
    },
    onClickImportTokenButton: async function () {
      this.importTokenModalVisable = true;
    },
    onClickImport: async function () {
      console.log("click import");
      this.tokenSelectOptions.push({
        label: this.importTokenSymbol + "(" + this.importTokenAddress + ")",
        value: this.importTokenAddress,
        symbol: this.importTokenSymbol,
      });
      this.selectedToken = this.importTokenAddress;
      this.importTokenModalVisable = false;
      this.importTokenAddress = undefined;
      this.importTokenName = undefined;
      this.importTokenSymbol = undefined;
      await this.getBalance();
    },
    switchMetamask: async function () {
      let { chainId } = this.envelopConfig;
      try {
        await switchNetwork(chainId);
      } catch (err) {
        console.log(err);
      }
      this.switchNetworkModalVisible = false;
    },
    doSwitchToken: async function () {
      this.getBalance();
    },
    getBalance: async function () {
      console.log("getBalance");
      if (this.selectedToken == undefined || this.metamaskAddress == undefined) {
        return;
      }
      if (this.selectedToken == ethers.constants.AddressZero) {
        this.balance = await providerInstance.getBalance(this.metamaskAddress);
        this.decimals = 18;
        this.allowance = undefined;
      } else {
        const contract = new ethers.Contract(this.selectedToken, ERC20_ABI, providerInstance);
        this.balance = await contract.balanceOf(this.metamaskAddress);
        this.decimals = await contract.decimals();
        this.allowance = await contract.allowance(this.metamaskAddress, ENVELOP_CONTRACT[this.network.chainId]);
      }
    },
    authorize: async function () {
      const contract = new ethers.Contract(this.selectedToken, ERC20_ABI, providerInstance);
      const connectedContract = contract.connect(providerInstance.getSigner());
      let tx;
      try {
        this.isLoading = true;
        this.modalOptions = { subTitle: "Confirm TX on MetaMask please" };
        tx = await connectedContract.approve(ENVELOP_CONTRACT[this.network.chainId], this.amountInWei);
      } catch (err) {
        this.$message.error(err.message);
        this.isLoading = false;
        return;
      }
      this.modalOptions.subTitle = `TX: "${tx.hash}" submited, waiting for confirm...`;
      this.modalOptions.txHash = tx.hash;
      try {
        const response = await tx.wait();
        console.log(response);
        this.getBalance();
        this.isLoading = false;
        this.$message.success("Approve success");
      } catch (err) {
        console.log(err);
        this.modalOptions.title = "Unknown";
        this.modalOptions.subTitle = `TX: "${tx.hash}" status is known, check it on blockchain`;
        this.modalOptions.status = "error";
      }
    },
    create: async function () {
      console.log("create");
      if (this.envelopConfig) {
        let envelopId = this.envelopConfig.envelopId;
        let merkelRoot = genMerkleRoot(this.envelopConfig.walletList);
        this.doCreate({
          envelopId: envelopId,
          selectedToken: this.selectedToken,
          amountInWei: this.amountInWei,
          shares: this.shares,
          merkelRoot: merkelRoot,
        });
      } else {
        let envelopId = UniqueId.uniqueId();
        let merkelRoot = ethers.utils.formatBytes32String("");
        if (!this.isPublicEnvelop) {
          const walletAddressList = getValidAddressList(this.inputAddressList);
          merkelRoot = genMerkleRoot(walletAddressList);
          const jsonData = {
            walletList: walletAddressList,
            check: ethers.utils.keccak256(ethers.utils.toUtf8Bytes(walletAddressList.join('') + process.env.VUE_APP_SERVER_SECRET))
          };
          const response = await axios.post('/api/save/candidate', jsonData)
          envelopId = response.data.envelopId
        }
        this.doCreate({
          envelopId: envelopId,
          selectedToken: this.selectedToken,
          amountInWei: this.amountInWei,
          shares: this.shares,
          merkelRoot: merkelRoot,
        });
      }
    },
    doCreate: async function (params) {
      console.log("doCreate");
      const contract = new ethers.Contract(
        ENVELOP_CONTRACT[this.network.chainId],
        ENVELOP_CONTRACT_ABI,
        providerInstance
      );
      const connectedContract = contract.connect(providerInstance.getSigner());
      let tx;
      try {
        let valueOption = {};
        if (params.selectedToken == ethers.constants.AddressZero) {
          valueOption = { value: params.amountInWei };
        }
        this.isLoading = true;
        this.modalOptions = { subTitle: "Confirm TX on MetaMask please" };
        tx = await connectedContract.create(
          params.envelopId,
          params.selectedToken,
          params.amountInWei,
          params.shares,
          params.merkelRoot,
          valueOption
        );
      } catch (err) {
        this.$message.error(err.message);
        this.isLoading = false;
        return;
      }
      this.modalOptions.subTitle = `TX: "${tx.hash}" submited, waiting for confirm...`;
      this.modalOptions.txHash = tx.hash;
      try {
        const response = await tx.wait();
        console.log(response);
        this.modalOptions.title = `EnvelopID: ${params.envelopId}`;
        const shareLink = `https://${window.location.host}/#/open?envelopId=${params.envelopId}&chainId=${this.network.chainId}`;
        this.modalOptions.subTitle = `Share this HongBao link "${shareLink}" to your friends`;
        this.modalOptions.copyText = shareLink;
        this.modalOptions.status = "success";
      } catch (err) {
        console.log(err);
        this.modalOptions.title = "Unknown";
        this.modalOptions.subTitle = `TX: "${tx.hash}" status is known, check it on blockchain`;
        this.modalOptions.status = "error";
      }
    },
    doClick: async function () {
      if (this.needAuthorize) {
        await this.authorize();
      } else {
        await this.create();
      }
    },
    onMetamaskEvent: async function (metamaskInfo) {
      this.metamaskAddress = metamaskInfo.address;
      this.network = metamaskInfo.network;
      const tokenList = TOKEN_CONFIG[this.network.chainId];
      if (tokenList != undefined && tokenList.length > 0) {
        this.tokenSelectOptions = tokenList.map((item, _) => ({
          label: item.symbol + " (" + item.address + ")",
          value: item.address,
          symbol: item.symbol,
        }));
        this.selectedToken = this.tokenSelectOptions[0].value;
      } else {
        this.$message.error(CHAIN_CONFIG[this.network.chainId].chainName + "Current network is not supported");
      }
      this.getBalance();
    },
    OnModalCloseEvent: function () {
      this.isLoading = false;
      window.location.reload();
    },
  },
  computed: {
    selectedTokenSymbol() {
      if (this.selectedToken) {
        for (const i in this.tokenSelectOptions) {
          if (this.tokenSelectOptions[i].value == this.selectedToken) {
            return this.tokenSelectOptions[i].symbol;
          }
        }
      }
      return "";
    },
    disableImportTokenButton() {
      if (this.importTokenName && this.importTokenSymbol) {
        return false;
      } else {
        return true;
      }
    },
    buttonName() {
      if (!this.amount) {
        return "Enter amount";
      }
      if (this.amount <= 0) {
        return "Wrong amount number";
      }
      if (this.insufficientBalance) {
        return "Insufficient Balance";
      }
      if (!this.shares) {
        return "Enter shares";
      }
      if (this.shares <= 0) {
        return "Wrong share number";
      }
      if (this.amountInWei < this.shares) {
        return "Amount must be greater than Shares";
      }
      if (!this.isPublicEnvelop && this.inputAddressList && getValidAddressList(this.inputAddressList).length < this.shares) {
        return "Candidates must be greater than Shares";
      }
      if (this.needAuthorize) {
        return "Authorize your Token";
      }

      return "Create HongBao";
    },
    isPublicEnvelop() {
      if (this.envelopConfig && this.envelopConfig.walletList.length > 0) {
        return false;
      }
      return this.publicChecked == "true" ? true : false;
    },
    insufficientBalance() {
      if (this.balance) {
        return this.balance.lt(this.amountInWei);
      }
      return false;
    },
    displayBalance() {
      return formatEther(this.balance, this.decimals);
    },
    displayAllowance() {
      return formatEther(this.allowance, this.decimals);
    },
    amountInWei() {
      if (this.amount) {
        return ethers.utils.parseUnits(String(this.amount), this.decimals);
      }
      return ethers.BigNumber.from(0);
    },
    protocalFee() {
      return formatEther(this.amountInWei.div(100000).mul(824), this.decimals);
    },
    needAuthorize() {
      if (this.balance && this.balance.lt(this.amountInWei)) {
        return false;
      }
      if (this.allowance && this.amount && this.selectedToken != ethers.constants.AddressZero) {
        return this.allowance.lt(this.amountInWei);
      }
      return false;
    },
    canClick() {
      if (!this.isPublicEnvelop) {
        if (!this.inputAddressList) {
          return false;
        } else {
          if (getValidAddressList(this.inputAddressList).length === 0) {
            return false;
          }
          if (getValidAddressList(this.inputAddressList).length < this.shares) {
            return false;
          }
        }
      }
      if (this.amountInWei.lte(0)) {
        return false;
      }
      if (this.shares == undefined) {
        return false;
      }
      if (this.shares <= 0) {
        return false;
      }
      if (this.balance.lt(this.amountInWei)) {
        return false;
      }
      if (this.amountInWei.lt(this.shares)) {
        return false;
      }
      if (this.selectedToken != ethers.constants.AddressZero && this.allowance && this.allowance.lt(this.amountInWei)) {
        return true;
      }
      return true;
    },
  },
  watch: {},
};
</script>

<style scoped>
</style>
