<template>
  <a-layout class="layout">
    <a-layout-header>
      <a-row justify="space-between">
        <a-col>
          <envelop-header></envelop-header>
        </a-col>
        <a-col>
          <connect-wallet @metamaskEvent="onMetamaskEvent"></connect-wallet>
        </a-col>
      </a-row>
    </a-layout-header>
    <a-layout-content>
      <envelop-info v-show="false" :envelopId="envelopId" @envelopInfoEvent="onEnvelopInfoEvent"></envelop-info>
      <envelop-tab selected="drawback" />
      <a-divider style="margin: 10px 0px" />

      <a-space direction="vertical" size="middle">
        <div>
          <a-row justify="center" style="font-size: 120%"> Drawback </a-row>
        </div>
        <div>
          <a-row>
            <a-input-number
              v-model:value="envelopId"
              placeholder="Input HongBao ID"
              size="large"
              class="row-input"
            />
          </a-row>
          <a-row justify="center" v-if="envelopInfo">
            <a-space>
              <a-col>Remain count: {{ envelopInfo.remainCount }}</a-col>
              <a-col>Remain money: {{ envelopInfo.remainMoneyFormatted }} {{ envelopInfo.symbol }}</a-col>
              <a-col><router-link :to="`/info/${envelopId}`">View</router-link></a-col>
            </a-space>
          </a-row>
          <a-row justify="center" v-if="expireTimeFormmatted">
            <a-col>Expire time: {{ expireTimeFormmatted }}</a-col>
          </a-row>
        </div>
        <div>
          <a-row>
            <a-button type="primary" :disabled="!canDrawback" v-on:click="drawback" class="row-button">
              Drawback
            </a-button>
          </a-row>
        </div>
      </a-space>
    </a-layout-content>
    <a-layout-footer>
      <envelop-footer></envelop-footer>
    </a-layout-footer>
  </a-layout>

  <a-modal v-model:visible="switchNetworkModalVisible" title="Confirm switch network" @ok="switchMetamask">
    <p>You need switch chain network to proceed</p>
  </a-modal>
  <template v-if="isLoading">
    <loading-modal :options="modalOptions" @modalCloseEvent="OnModalCloseEvent"> </loading-modal>
  </template>
</template>

<script>
import { providerInstance, switchNetwork } from "../utils/metamask.js";
import { formatEther, formatDate } from "../utils/utils.js";
import ConnectWallet from "./ConnectWallet.vue";
import LoadingModal from "./LoadingModal.vue";
import EnvelopInfo from "./EnvelopInfo.vue";
import EnvelopHeader from "@/components/EnvelopHeader.vue";
import EnvelopTab from "@/components/EnvelopTab.vue";
import EnvelopFooter from "@/components/EnvelopFooter.vue";
import { ENVELOP_CONTRACT, ENVELOP_CONTRACT_ABI } from "@/constants/Contract";
import { ethers } from "ethers";
export default {
  components: {
    ConnectWallet,
    EnvelopInfo,
    LoadingModal,
    EnvelopHeader,
    EnvelopTab,
    EnvelopFooter,
  },
  data: function () {
    return {
      switchNetworkModalVisible: false,
      isLoading: false,
      modalOptions: {},
      network: undefined,
      targetChainId: undefined,
      envelopId: undefined,
      envelopInfo: undefined,
      metamaskAddress: undefined,
      canDrawback: false,
    };
  },
  created: async function () {
    this.targetChainId = Number(this.$route.query.chainId);
    this.envelopId = this.$route.query.envelopId;
  },
  mounted: async function () {},
  methods: {
    switchMetamask: async function () {
      try {
        await switchNetwork(this.targetChainId);
      } catch (err) {
        console.log(err);
        this.$message.error(err.message);
      }
      this.switchNetworkModalVisible = false;
    },
    getStatus: async function () {
      console.log("getStatus");
      if (this.envelopInfo.creator == ethers.constants.AddressZero) {
        this.$message.error("Invalid EnvelopId");
        this.canDrawback = false;
        return;
      }
      if (this.envelopInfo.creator != this.metamaskAddress) {
        this.$message.error("This HongBao is not created by you");
        this.canDrawback = false;
        return;
      }
      if (this.envelopInfo.remainCount == 0) {
        this.$message.error("No money left in this HongBao");
        this.canDrawback = false;
        return;
      }
      if (ethers.utils.formatUnits(this.envelopInfo.expireTime, "wei") > Date.now() / 1000) {
        this.$message.error("This HongBao is not expired");
        this.canDrawback = false;
        return;
      }
      this.canDrawback = true;
    },
    drawback: async function () {
      console.log("drawback");
      const contract = new ethers.Contract(
        ENVELOP_CONTRACT[this.network.chainId],
        ENVELOP_CONTRACT_ABI,
        providerInstance
      );
      const connectedContract = contract.connect(providerInstance.getSigner());
      let tx;
      try {
        this.isLoading = true;
        this.modalOptions = { subTitle: "Confirm TX on MetaMask please" };
        tx = await connectedContract.drawback(this.envelopId);
      } catch (err) {
        this.$message.error(err.message);
        this.isLoading = false;
        return;
      }
      this.modalOptions.subTitle = `TX: "${tx.hash}" submited, waiting for confirm...`;
      this.modalOptions.txHash = tx.hash;
      try {
        const response = await tx.wait();
        console.log(response);

        let amount;
        for (const log of response.logs) {
          if (log.topics[0] == ethers.utils.keccak256(ethers.utils.toUtf8Bytes('Drawback(uint64,address,uint256)'))) {
            amount = log.data
            break;
          }
        }

        const wei = ethers.utils.formatUnits(amount, "wei");
        this.modalOptions.title = "Success";
        this.modalOptions.subTitle = `Congratulations! You got ${formatEther(wei, this.envelopInfo.decimals)} ${
          this.envelopInfo.symbol
        }`;
        this.modalOptions.status = "success";
      } catch (err) {
        console.log(err);
        this.modalOptions.title = "Unknown";
        this.modalOptions.subTitle = `TX: "${tx.hash}" status is known, check it on blockchain`;
        this.modalOptions.status = "error";
      }
      this.isLoading = false;
    },
    doGetStatus: function () {
      if (this.metamaskAddress && this.network && this.envelopInfo) {
        this.getStatus();
      }
    },
    onMetamaskEvent: async function (metamaskInfo) {
      this.metamaskAddress = metamaskInfo.address;
      this.network = metamaskInfo.network;
      if (this.targetChainId && this.targetChainId != this.network.chainId) {
        this.switchNetworkModalVisible = true;
      }
      this.doGetStatus();
    },
    onEnvelopInfoEvent: async function (envelopInfo) {
      this.envelopInfo = envelopInfo;
      this.doGetStatus();
    },
    OnModalCloseEvent: function () {
      this.isLoading = false;
      window.location.reload();
    },
  },
  computed: {
    expireTimeFormmatted: function () {
      if (this.envelopInfo) {
        return formatDate(this.envelopInfo.expireTimeDateObject);
      }
      return undefined;
    },
  },
  watch: {},
};
</script>

<style scoped>
</style>
