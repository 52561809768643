<template>
  <a-layout class="layout">
    <a-layout-header>
      <a-row justify="space-between">
        <a-col>
          <envelop-header></envelop-header>
        </a-col>
        <a-col>
          <connect-wallet @metamaskEvent="onMetamaskEvent"></connect-wallet>
        </a-col>
      </a-row>
    </a-layout-header>
    <a-layout-content>
      <a-row justify="space-between">
        <a-col style="font-size: 140%">HongBao List</a-col>
        <a-col><a-button type="primary" v-on:click="giftNow"> Gift Now </a-button></a-col>
      </a-row>
      <a-divider style="margin: 10px 0" />
      <a-table sticky size="middle" :scroll="{ x: 750 }"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :pagination="pagination"
        @change="handleTableChange"
      >
        <template #headerCell> </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'id'">
            <a-tooltip>
              <template #title>{{ record.envelop_id }}</template>
              <router-link :to="`/info/${record.envelop_id}`">{{ getFormattedEnvelopId(record.envelop_id) }}</router-link>
            </a-tooltip>
          </template>
          <template v-if="column.key === 'chain'">
            {{ getChainName(record.chain_id) }}
          </template>
          <template v-if="column.key === 'tx'">
            <a-tooltip>
              <template #title>{{ record.tx_hash }}</template>
              <a :href="getChainScanner(record.chain_id) + '/tx/' + record.tx_hash" target="_blank">
                {{ getFormattedTX(record.tx_hash) }}
              </a>
            </a-tooltip>
          </template>
          <template v-if="column.key === 'creator'">
            <a-tooltip>
              <template #title>{{ record.creator }}</template>
              <a :href="getChainScanner(record.chain_id) + '/address/' + record.creator" target="_blank">
                {{ getFormattedTX(record.creator) }}
              </a>
            </a-tooltip>
          </template>
          <template v-if="column.key === 'token'">
            <div v-if="isNativeCurrency(record.token_address)">
              <div>{{ record.token_symbol }}</div>
              <!-- <div>{{ record.token_name }}</div> -->
            </div>
            <div v-else>
              <a-tooltip>
                <template #title>{{ record.token_address }}</template>
                <div>
                  <a :href="getChainScanner(record.chain_id) + '/address/' + record.token_address" target="_blank">
                    {{ record.token_symbol }}
                  </a>
                </div>
                <!-- <div>
                  <a :href="getChainScanner(record.chain_id) + '/address/' + record.token_address" target="_blank">
                    {{ record.token_name }}
                  </a>
                </div> -->
              </a-tooltip>
            </div>
          </template>
          <template v-if="column.key === 'status'">
            <a-tooltip>
              <template #title
                >{{ getFormattedAmount(record.remain_money, record.token_decimals) }} {{ record.token_symbol }} left</template
              >
              {{ record.count - record.remain_count }}/{{ record.count }}
            </a-tooltip>
            <div>
              <a-tag class="single-tag" :color="record.is_public ? 'green' : 'blue' ">
                {{ record.is_public ? "Public" : "Private" }}
              </a-tag>
            </div>
          </template>
          <template v-if="column.key === 'amount'">
            <span>{{ getFormattedAmount(record.money, record.token_decimals) }}</span>
          </template>
          <template v-if="column.key === 'expire'">
            {{ getFormattedTime(record.expire_time).split(' ')[0] }}
            {{ getFormattedTime(record.expire_time).split(' ')[1] }}
          </template>
          <template v-if="column.key === 'action'">
            <div><router-link :to="`/info/${record.envelop_id}`">View </router-link></div>
            <div>
              <router-link :to="`/open?envelopId=${record.envelop_id}&chainId=${record.chain_id}`">Open </router-link>
            </div>
            <div>
              <router-link :to="`/drawback?envelopId=${record.envelop_id}&chainId=${record.chain_id}`"
                >Drawback
              </router-link>
            </div>
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </a-layout>
  <a-layout-footer>
    <envelop-footer></envelop-footer>
  </a-layout-footer>
</template>
<script>
import ConnectWallet from "./ConnectWallet.vue";
import EnvelopHeader from "@/components/EnvelopHeader.vue";
import EnvelopFooter from "@/components/EnvelopFooter.vue";
import { CHAIN_CONFIG, SUPPORTED_CHAIN } from "@/constants/ChainConfig";
import { formatEther, formatDate } from "@/utils/utils";
import axios from "axios";
import { ethers } from "ethers";
const COLUMNS = [
  "ID",
  "CHAIN",
  "TX",
  "CREATOR",
  "AMOUNT",
  "TOKEN",
  "STATUS",
  "EXPIRE",
  "ACTION",
];

const COLUMNS_PROPS = {
  'ID': {
    fixed: 'left'
  },
  'AMOUNT': {
    align: 'center'
  },
  'STATUS': {
    align: 'center'
  },
  'ACTION': {
    fixed: 'right'
  }
}

export default {
  components: {
    ConnectWallet,
    EnvelopHeader,
    EnvelopFooter,
  },
  data: function () {
    return {
      network: undefined,
      chainId: undefined,
      pagination: {
        // total: 200,
        current: 1,
        pageSize: 10,
      },
      loading: false,
      columns: [],
      data: [],
    };
  },
  created: function () {
    COLUMNS.forEach((item) => {
      if (item == "CHAIN") {
        let filterList = [];
        SUPPORTED_CHAIN.forEach((chainId) => {
          filterList.push({
            text: CHAIN_CONFIG[chainId].chainName,
            value: chainId,
          });
        });
        this.columns.push({
          title: item,
          dataIndex: item.toLowerCase(),
          key: item.toLowerCase(),
          filters: filterList,
          filterMultiple: false,
        });
      } else {
        let props = COLUMNS_PROPS[item] || {}
        this.columns.push({
          title: item,
          dataIndex: item.toLowerCase(),
          key: item.toLowerCase(),
          ...props,
        });
      }
    });
    this.fetch_data();
  },
  methods: {
    fetch_data: async function () {
      let url = `/api/list/envelopInfo?start=${this.pagination.current - 1}&size=${this.pagination.pageSize}`;
      if (this.chainId) {
        url = url + `&chainId=${this.chainId}`;
      }
      const response = await axios.get(url);
      this.pagination.current = response.data.page_index + 1;
      this.pagination.pageSize = response.data.page_size;
      this.pagination.total = response.data.total;
      this.data = response.data.data_list;
    },
    getFormattedTX: function (tx) {
      const suffix = tx.slice(-4);
      const prefix = tx.slice(0, 6);
      return prefix + "..." + suffix;
    },
    getFormattedEnvelopId: function (envelopId) {
      envelopId = String(envelopId);
      const suffix = envelopId.slice(-4);
      const prefix = envelopId.slice(0, 4);
      return prefix + "..." + suffix;
    },
    getChainName: function (chainId) {
      return CHAIN_CONFIG[chainId].chainName;
    },
    getChainScanner: function (chain_id) {
      return CHAIN_CONFIG[chain_id].blockExplorerUrls;
    },
    getFormattedAmount: function (amount, decimals) {
      return formatEther(amount, decimals);
    },
    getFormattedTime: function (timestamp) {
      return formatDate(new Date(timestamp * 1000));
    },
    giftNow: function () {
      this.$router.push({ path: "/create" });
    },
    isNativeCurrency: function (token_address) {
      return token_address == ethers.constants.AddressZero ? true : false;
    },
    onMetamaskEvent: function (metamaskInfo) {
      this.network = metamaskInfo.network;
      // this.fetch_data()
    },
    handleTableChange: function (pagination, filters) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      if (filters && filters.chain) {
        this.chainId = filters.chain[0];
        console.log(this.chainId);
      } else {
        this.chainId = undefined;
      }
      this.fetch_data();
    },
  },
};
</script>
<style scoped>
#app .ant-layout-content {
  padding: 10px 10px;
}
</style>
