<template>
  <a-row class="tab-row" justify="center">
    <a-col :md="3" :xs="6" v-if="selected == 'create'">
      <span style="font-size: 120%">Create</span>
    </a-col>
    <a-col :md="3" :xs="6" v-if="selected != 'create'">
      <router-link to="/create">
        <span style="font-size: 120%">Create</span>
      </router-link>
    </a-col>
    <a-col :md="3" :xs="6" v-if="selected == 'open'">
      <span style="font-size: 120%">Open</span>
    </a-col>
    <a-col :md="3" :xs="6" v-if="selected != 'open'">
      <router-link to="/open">
        <span style="font-size: 120%">Open</span>
      </router-link>
    </a-col>
    <a-col :md="3" :xs="6" v-if="selected == 'drawback'">
      <span style="font-size: 120%">Drawback</span>
    </a-col>
    <a-col :md="3" :xs="6" v-if="selected != 'drawback'">
      <router-link to="/drawback">
        <span style="font-size: 120%">Drawback</span>
      </router-link>
    </a-col>
  </a-row>
</template>
<script>
export default {
  data: function () {
    return {
      // selected: undefined
    };
  },
  props: ["selected"],
};
</script>
<style scoped>
</style>
