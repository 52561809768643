const { CHAIN_ID } = require("./ChainConfig.js");

export const TOKEN_CONFIG = {
  [CHAIN_ID.MAINNET]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      name: "ETH",
      symbol: "ETH",
    },
    {
      address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      decimals: 6,
      name: "Tether USD",
      symbol: "USDT",
    },
    {
      address: "0xB8c77482e45F1F44dE1745F52C74426C631bDD52",
      decimals: 18,
      name: "BNB",
      symbol: "BNB",
    },
    {
      address: "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
      decimals: 18,
      name: "SHIBA INU",
      symbol: "SHIB",
    },
    {
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      decimals: 6,
      name: "USD Coin",
      symbol: "USDC",
    },
    {
      address: "0x4Fabb145d64652a948d72533023f6E7A623C7C53",
      decimals: 18,
      name: "Binance USD",
      symbol: "BUSD",
    },
    {
      address: "0x514910771AF9Ca656af840dff83E8264EcF986CA",
      decimals: 18,
      name: "ChainLink Token",
      symbol: "LINK",
    },
    {
      address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      decimals: 8,
      name: "Wrapped BTC",
      symbol: "WBTC",
    },
    {
      address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      decimals: 18,
      name: "Dai Stablecoin",
      symbol: "DAI",
    },
    {
      address: "0x1c7E83f8C581a967940DBfa7984744646AE46b29",
      decimals: 18,
      name: "random",
      symbol: "RND",
    },
  ],
  [CHAIN_ID.ROPSTEN]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      name: "ETH",
      symbol: "ETH",
    },
    {
      address: "0xaD6D458402F60fD3Bd25163575031ACDce07538D",
      decimals: 18,
      name: "DAI",
      symbol: "DAI",
    },
    {
      address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
      decimals: 18,
      name: "Uniswap",
      symbol: "UNI",
    },
    {
      address: "0xc778417E063141139Fce010982780140Aa0cD5Ab",
      decimals: 18,
      name: "Wrapped Ether",
      symbol: "WETH",
    },
  ],
  [CHAIN_ID.RINKEBY]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      name: "ETH",
      symbol: "ETH",
    },
    {
      address: "0x06d771556d960aC82729F609dD0f0e2cF485aD3c",
      decimals: 18,
      name: "CrepassTestToken",
      symbol: "CTT",
    },
    {
      address: "0x8F89366B48b9aD9480D08bBC633Ab6807a489b34",
      decimals: 18,
      name: " SeeDAO Token",
      symbol: "SEED",
    },
  ],
  [CHAIN_ID.BSC_MAINNET]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      name: "Binance Coin",
      symbol: "BNB",
    },
    {
      name: "ALICE",
      symbol: "ALICE",
      address: "0xAC51066d7bEC65Dc4589368da368b212745d63E8",
      decimals: 6,
    },
    {
      name: "Axie Infinity Shard",
      symbol: "AXS",
      address: "0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0",
      decimals: 18,
    },
    {
      name: "Binance Pegged Bitcoin",
      symbol: "BTCB",
      address: "0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
      decimals: 18,
    },
    {
      name: "Binance Pegged BUSD",
      symbol: "BUSD",
      address: "0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56",
      decimals: 18,
    },
    {
      name: "Dogecoin",
      symbol: "DOGE",
      address: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43",
      decimals: 8,
    },
    {
      name: "EOS Token",
      symbol: "EOS",
      address: "0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6",
      decimals: 18,
    },
    {
      name: "Binance Pegged ETH",
      symbol: "ETH",
      address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8",
      decimals: 18,
    },
    {
      name: "ChainLink Token",
      symbol: "LINK",
      address: "0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD",
      decimals: 18,
    },
    {
      name: "Binance Pegged USD Coin",
      symbol: "USDC",
      address: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
      decimals: 18,
    },
    {
      name: "Binance Pegged USDT",
      symbol: "USDT",
      address: "0x55d398326f99059fF775485246999027B3197955",
      decimals: 18,
    },
    {
      name: "Mamba Coin",
      symbol: "MAMB",
      address: "0xab1Ec376cCAd1BFd5E3409A60bD30988Af2dC765",
      decimals: 18,
    },
  ],
  [CHAIN_ID.POLYGON]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      name: "Polygon Matic",
      symbol: "MATIC",
    },
    {
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      decimals: 18,
      name: "Tether USD",
      symbol: "USDT",
    },
  ],
  [CHAIN_ID.BSC_TESTNET]: [
    {
      address: "0x0000000000000000000000000000000000000000",
      decimals: 18,
      name: "Binance Coin",
      symbol: "BNB",
    },
    {
      address: "0xae13d989dac2f0debff460ac112a837c89baa7cd",
      decimals: 18,
      name: "Wrapped BNB",
      symbol: "WBNB",
    },
    {
      address: "0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee",
      decimals: 18,
      name: "BUSD Token",
      symbol: "BUSD",
    },
    {
      address: "0xec5dcb5dbf4b114c9d0f65bccab49ec54f6a0867",
      decimals: 18,
      name: "DAI Token",
      symbol: "DAI",
    },
  ],
};
