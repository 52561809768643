<template>
  <a-layout class="layout">
    <a-layout-header>
      <a-row justify="space-between">
        <a-col>
          <envelop-header></envelop-header>
        </a-col>
        <a-col>
          <connect-wallet @metamaskEvent="onMetamaskEvent"></connect-wallet>
        </a-col>
      </a-row>
    </a-layout-header>
    <a-layout-content>
      <a-row justify="space-between">
        <a-col style="font-size: 140%">HongBao Info</a-col>
        <a-col>
          <a-space>
            <a-button type="primary" v-on:click="giftNow"> Gift Now </a-button>
            <a-button type="primary" v-on:click="open"> Open </a-button>
            <a-button type="primary" v-on:click="drawback"> Drawback </a-button>
          </a-space>
        </a-col>
      </a-row>
      <a-divider style="margin: 10px 0" />
      <div v-if="envelopInfo" style="margin: 10px; line-height: 2">
        <a-row justify="space-between">
          <a-col>HongBao ID</a-col>
          <a-col>{{ envelopInfo.envelop_id }}</a-col>
        </a-row>
        <a-row justify="space-between">
          <a-col>Chain Name</a-col>
          <a-col>{{ getChainName(envelopInfo.chain_id) }}</a-col>
        </a-row>
        <a-row justify="space-between">
          <a-col>Type</a-col>
          <a-col>{{ envelopInfo.is_public ? "Public" : "Private" }}</a-col>
        </a-row>
        <a-row justify="space-between">
          <a-col>TX</a-col>
          <a-col>
            <a-typography-paragraph>
              <a :href="getChainScanner(envelopInfo.chain_id) + '/tx/' + envelopInfo.tx_hash" target="_blank">
                {{ envelopInfo.tx_hash }}
              </a>
            </a-typography-paragraph>
          </a-col>
        </a-row>
        <a-row justify="space-between">
          <a-col>Creator</a-col>
          <a-col>
            <a-typography-paragraph>
              <a :href="getChainScanner(envelopInfo.chain_id) + '/address/' + envelopInfo.creator" target="_blank">
                {{ envelopInfo.creator }}
              </a>
            </a-typography-paragraph>
          </a-col>
        </a-row>
        <a-row justify="space-between">
          <a-col>Amount</a-col>
          <a-col
            >{{
              getFormattedAmount(
                BigInt(envelopInfo.money) - BigInt(envelopInfo.remain_money),
                envelopInfo.token_decimals
              )
            }}/{{ getFormattedAmount(envelopInfo.money, envelopInfo.token_decimals) }}</a-col
          >
        </a-row>
        <a-row justify="space-between">
          <a-col>Protocol Fee</a-col>
          <a-col> {{ getFormattedAmount(envelopInfo.fee, envelopInfo.token_decimals) }} </a-col>
        </a-row>
        <a-row justify="space-between">
          <a-col>Shares</a-col>
          <a-col> {{ envelopInfo.count - envelopInfo.remain_count }}/{{ envelopInfo.count }}</a-col>
        </a-row>
        <a-row justify="space-between">
          <a-col>Token</a-col>
          <a-col>{{ envelopInfo.token_symbol }}/{{ envelopInfo.token_name }}</a-col>
        </a-row>
        <a-row justify="space-between">
          <a-col>Token Address</a-col>
          <a-col v-if="isNativeCurrency(envelopInfo.token_address)">NA</a-col>
          <a-col v-else>
            <a-typography-paragraph>
              <a
                :href="getChainScanner(envelopInfo.chain_id) + '/address/' + envelopInfo.token_address"
                target="_blank"
              >
                {{ envelopInfo.token_address }}
              </a>
            </a-typography-paragraph>
          </a-col>
        </a-row>
        <a-row justify="space-between">
          <a-col>Expire Time</a-col>
          <a-col> {{ getFormattedTime(envelopInfo.expire_time) }} </a-col>
        </a-row>
      </div>
      <a-divider style="margin: 20px 0" />

      <a-row justify="center" style="font-size: 120%">HongBao Log</a-row>
      <a-divider style="margin: 0 0 20px 0" />

      <a-table sticky size="middle" :scroll="{ x: 420 }"
               :columns="columns"
               :data-source="envelopEventList"
               :pagination="false"
      >
        <template #summary>
          <a-table-summary fixed="bottom">
            <a-table-summary-row>
              <a-table-summary-cell :index="0" :col-span="5" align="center">
                <a-button v-on:click="loadMoreEvent" :disabled="loadMoreEventDisabled" size="small">More</a-button>
              </a-table-summary-cell>
            </a-table-summary-row>
          </a-table-summary>
        </template>
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'tx'">
            <a-tooltip>
              <template #title>{{ record.tx_hash }}</template>
              <a :href="getChainScanner(record.chain_id) + '/tx/' + record.tx_hash" target="_blank">
                {{ getFormattedTX(record.tx_hash) }}
              </a>
            </a-tooltip>
          </template>
          <template v-if="column.key === 'type'">
            <a-typography-paragraph>{{ getOperationType(record.operation) }} </a-typography-paragraph>
          </template>
          <template v-if="column.key === 'address'">
            <a-tooltip>
              <template #title>{{ record.tx_from }}</template>
              <a :href="getChainScanner(record.chain_id) + '/address/' + record.tx_from" target="_blank">
                {{ record.tx_from }}
              </a>
            </a-tooltip>
          </template>
          <template v-if="column.key === 'amount'">
            <div>
              {{ getFormattedAmount(record.money, envelopInfo.token_decimals) }}
            </div>
            <div>
              {{ envelopInfo.token_symbol }}
            </div>
          </template>
          <template v-if="column.key === 'time'">
            <a-typography-paragraph>
              {{ getFormattedTime(record.block_timestamp) }}
            </a-typography-paragraph>
          </template>
        </template>
      </a-table>

      <a-divider style="margin: 20px 0" />

      <a-row justify="center" style="font-size: 120%">Candidate List</a-row>
      <a-divider style="margin: 0 0 20px 0" />
      <div v-if="envelopInfo">
        <div v-if="envelopInfo.is_public">Anyone can open this HongBao</div>
        <div v-else>
          <div>
            Merkel Root
            <a-typography-paragraph>{{ envelopInfo.merkel_root }}</a-typography-paragraph>
          </div>
          <a-row justify="start">
            <a-col :xs="24" :md="12" v-for="candidate in envelopCandidateList" :key="candidate">
              <a-typography-paragraph>
                {{ candidate }}
              </a-typography-paragraph>
            </a-col>
          </a-row>
        </div>
      </div>
    </a-layout-content>
  </a-layout>
  <a-layout-footer>
    <envelop-footer></envelop-footer>
  </a-layout-footer>
</template>
<script>
import ConnectWallet from "./ConnectWallet.vue";
import EnvelopHeader from "@/components/EnvelopHeader.vue";
import EnvelopFooter from "@/components/EnvelopFooter.vue";
import { CHAIN_CONFIG, SUPPORTED_CHAIN } from "@/constants/ChainConfig";
import { formatEther, formatDate } from "@/utils/utils";
import axios from "axios";
import { ethers } from "ethers";
const COLUMNS = [
  "TX", 
  "TYPE", 
  "ADDRESS", 
  "AMOUNT", 
  "TIME"];
const COLUMNS_PROPS = {
  'AMOUNT': {
    align: 'center'
  }
}

export default {
  components: {
    ConnectWallet,
    EnvelopHeader,
    EnvelopFooter,
  },
  data: function () {
    return {
      envelopId: undefined,
      envelopInfo: undefined,
      envelopEventList: [],
      envelopEventTotal: 0,
      envelopEventPageIndex: 0,
      envelopEventPageSize: 10,
      envelopCandidateList: [],
      columns: [],
      data: [],
    };
  },
  created: function () {
    this.envelopId = this.$route.params.envelopId;

    COLUMNS.forEach((item) => {
        let props = COLUMNS_PROPS[item] || {}
        this.columns.push({
          title: item,
          dataIndex: item.toLowerCase(),
          key: item.toLowerCase(),
          ...props,
        });
    });

    this.fetch_data();
  },
  methods: {
    fetch_data: async function () {
      let url = `/api/envelopInfo?envelopId=${this.envelopId}`;
      let response = await axios.get(url);
      this.envelopInfo = response.data;
      url = `/api/list/envelopEvent?envelopId=${this.envelopId}&start=${this.envelopEventPageIndex}&size=${this.envelopEventPageSize}`;
      response = await axios.get(url);
      this.envelopEventList = this.envelopEventList.concat(response.data.data_list);
      this.envelopEventTotal = response.data.total;
      if (!this.envelopInfo.is_public) {
        url = `/api/list/candidate?envelopId=${this.envelopId}`;
        response = await axios.get(url);
        this.envelopCandidateList = response.data.walletList;
      }
    },
    loadMoreEvent: async function () {
      console.log("aaa");
      this.envelopEventPageIndex = this.envelopEventPageIndex + 1;
      const url = `/api/list/envelopEvent?envelopId=${this.envelopId}&start=${this.envelopEventPageIndex}&size=${this.envelopEventPageSize}`;
      const response = await axios.get(url);
      this.envelopEventList = this.envelopEventList.concat(response.data.data_list);
      this.envelopEventTotal = response.data.total;
    },
    getOperationType: function (operation) {
      if (operation == 0) {
        return "Create";
      } else if (operation == 1) {
        return "Open";
      } else if (operation == 2) {
        return "Drawback";
      } else {
        return "unknown";
      }
    },
    getFormattedTX: function (tx) {
      const suffix = tx.slice(-4);
      const prefix = tx.slice(0, 6);
      return prefix + "..." + suffix;
    },
    getFormattedEnvelopId: function (envelopId) {
      envelopId = String(envelopId);
      const suffix = envelopId.slice(-4);
      const prefix = envelopId.slice(0, 4);
      return prefix + "..." + suffix;
    },
    getChainName: function (chainId) {
      return CHAIN_CONFIG[chainId].chainName;
    },
    getChainScanner: function (chain_id) {
      return CHAIN_CONFIG[chain_id].blockExplorerUrls;
    },
    getFormattedAmount: function (amount, decimals) {
      return formatEther(amount, decimals);
    },
    getFormattedTime: function (timestamp) {
      return formatDate(new Date(timestamp * 1000));
    },
    giftNow: function () {
      this.$router.push({ path: "/create" });
    },
    open: function () {
      this.$router.push({ path: "/open", query: { chainId: this.envelopInfo.chain_id, envelopId: this.envelopId } });
    },
    drawback: function () {
      this.$router.push({
        path: "/drawback",
        query: { chainId: this.envelopInfo.chain_id, envelopId: this.envelopId },
      });
    },
    isNativeCurrency: function (token_address) {
      return token_address == ethers.constants.AddressZero ? true : false;
    },
    onMetamaskEvent: function (metamaskInfo) {
      this.network = metamaskInfo.network;
      // this.fetch_data()
    },
  },
  computed: {
    loadMoreEventDisabled() {
      if (this.envelopEventList.length == this.envelopEventTotal) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
#app .ant-layout-content {
  padding: 10px 10px;
}
</style>
