<template>
  <div class="large">
    <router-link to="/">
      <img src="/logo.png" alt="" width="50" height="50"/>
    </router-link>
    <router-link to="/">
      <span style="font-size: 140%">Home</span>
    </router-link>
    <a-divider type="vertical" style="height: 20px"/>
    <router-link to="/list">
      <span style="font-size: 140%">HongBao</span>
    </router-link>
  </div>
  <div class="small">
    <router-link to="/">
      <img src="/logo.png" alt="" width="50" height="50"/>
    </router-link>
    <a-dropdown>
      <template #overlay>
        <a-menu>
          <a-menu-item key="1">
            <router-link to="/">
              <span style="font-size: 140%">Home</span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="2">
            <router-link to="/list">
              <span style="font-size: 140%">HongBao</span>
            </router-link>
          </a-menu-item>
        </a-menu>
      </template>
      <bars-outlined :style="{fontSize: '30px', verticalAlign: 'middle', color: '#08c'}" />
    </a-dropdown>
  </div>
</template>
<script>
import {
  BarsOutlined
} from '@ant-design/icons-vue';


export default {
  components: {
    BarsOutlined
  },
  data: function () {
    return {}
  },
}
</script>
<style scoped>
.large {
  display: block;
}
.small {
  display: none;
}
@media (max-width: 576px) {
  .large {
    display: none;
  }
  .small {
    display: block;
  }
}
</style>
