<template>
  <a-row align="middle" :gutter="[16, 16]">
    <a-col v-if="address == undefined">
      <a-button type="primary" v-on:click="connect">Connect Wallet</a-button>
    </a-col>
    <a-col v-if="network">
      <a-select
        v-model:value="selectedNetwork"
        :options="networkOptions"
        @change="doSwitchNetwork"
        style="width: 150px"
      ></a-select>
    </a-col>
    <a-col v-if="balance != undefined">
      <a-row>
        <a-typography-text style="line-height: 16px">{{ balanceFormatted }} {{ symbol }}</a-typography-text>
      </a-row>
      <a-row v-if="address" :style="balance != undefined ? 'margin-top: 4px' : ''">
        <a-typography-text style="line-height: 16px">
          <a-tooltip>
            <template #title>{{ address }}</template>
            {{ formattedAddress }}
          </a-tooltip>
        </a-typography-text>
      </a-row>
    </a-col>
  </a-row>
</template>

<script>
import { providerInstance, switchNetwork } from "../utils/metamask";
import { formatAddress, formatEther, LocalStorageService } from "@/utils/utils";
import { CHAIN_CONFIG, SUPPORTED_CHAIN } from "@/constants/ChainConfig";
import { STORAGE_KEY_ADDRESS } from "@/constants/Constants";
import { ethers } from "ethers";

export default {
  data: function () {
    return {
      address: undefined,
      formattedAddress: undefined,
      network: undefined,
      networkName: undefined,
      balance: undefined,
      balanceFormatted: undefined,
      symbol: undefined,
      decimals: undefined,
      networkOptions: undefined,
      selectedNetwork: undefined,
    };
  },
  created: async function () {
    this.initAddressListener();
    this.initNetworkOptions();
    if (LocalStorageService.get(STORAGE_KEY_ADDRESS)) {
      this.initWallet();
    }
  },
  methods: {
    initWallet: async function () {
      const accounts = await providerInstance.send("eth_requestAccounts", []);
      this.address = ethers.utils.getAddress(accounts[0]);
      this.formattedAddress = formatAddress(this.address);
      this.network = await providerInstance.getNetwork();
      this.networkName = CHAIN_CONFIG[this.network.chainId].chainName;
      this.symbol = CHAIN_CONFIG[this.network.chainId].nativeCurrency.symbol;
      this.balance = await providerInstance.getBalance(this.address);
      this.decimals = CHAIN_CONFIG[this.network.chainId].nativeCurrency.decimals;
      this.balanceFormatted = formatEther(this.balance, this.decimals);
      LocalStorageService.set(STORAGE_KEY_ADDRESS, {
        chainId: this.network.chainId,
        address: this.address,
      });
      this.selectedNetwork = 0;
      if (this.network) {
        const networkId = this.network.chainId;
        if (SUPPORTED_CHAIN.includes(networkId)) {
          this.selectedNetwork = networkId;
        } else {
          this.$message.error("Please select a supported Network");
        }
      }
      this.$emit("metamaskEvent", {
        address: this.address,
        network: this.network,
        balance: this.balance,
        balanceFormatted: this.balanceFormatted,
        symbol: this.symbol,
        decimals: this.decimals,
      });
    },
    connect: async function () {
      if (providerInstance) {
        this.initWallet();
      } else {
        this.$message.error("Please install MetaMask plugin");
      }
    },
    initAddressListener: function () {
      window.ethereum.on("accountsChanged", (accounts) => {
        // Time to reload your interface with accounts[0]!
        console.log("accountsChanged", accounts);
        if (accounts.length == 0) {
          LocalStorageService.remove(STORAGE_KEY_ADDRESS);
          window.location.reload();
        }
        if (this.address == undefined) {
          return;
        }
        if (accounts.length > 0 && accounts[0] != this.address) {
          this.initWallet();
        }
      });
    },
    initNetworkOptions: function () {
      this.networkOptions = SUPPORTED_CHAIN.map((item, _) => ({
        value: item,
        label: CHAIN_CONFIG[item].chainName,
      }));
      this.networkOptions.unshift({
        value: 0,
        label: "Select a Network",
        disabled: true,
      });
    },
    doSwitchNetwork: async function () {
      if (this.selectedNetwork) {
        try {
          await switchNetwork(this.selectedNetwork);
          this.$message.success("Switch network success");
        } catch (err) {
          this.$message.error(err.message);
          this.selectedNetwork = this.network.chainId;
        }
      }
    },
  },
  watch: {},
  emits: ["metamaskEvent"],
};
</script>

<style scoped></style>
