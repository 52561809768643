<template>
  <a-divider style="margin: 10px 0px" />
  Powered by <a href="https://discord.gg/crepto" target="_blank">Crepto Community</a>
</template>
<script>
export default {
  data: function () {
    return {};
  },
};
</script>
<style scoped></style>
