import { createApp } from 'vue'
import App from './App.vue'

import {createRouter, createWebHashHistory} from 'vue-router'
import 'ant-design-vue/dist/antd.css';

import HomePage from './components/HomePage.vue'
import CreateEnvelop from './components/CreateEnvelop.vue'
import OpenEnvelop from './components/OpenEnvelop.vue'
import DrawbackEnvelop from './components/DrawbackEnvelop.vue'
import InfoEnvelop from './components/InfoEnvelop.vue'
import ListEnvelop from './components/ListEnvelop.vue'
import TestTest from './components/TestTest.vue'

import {
    Button,
    Modal,
    Select,
    Input,
    InputNumber,
    Radio,
    Layout,
    Divider,
    Space,
    Spin,
    Result,
    Tabs,
    Row,
    Col,
    Typography,
    Tooltip,
    Table,
    List,
    Dropdown,
    Menu,
    Tag,
    message
} from 'ant-design-vue';


const routes = [
    { path: '/', component: HomePage },
    { path: '/create', component: CreateEnvelop },
    { path: '/open', component: OpenEnvelop},
    { path: '/drawback', component: DrawbackEnvelop},
    { path: '/info/:envelopId', component: InfoEnvelop},
    { path: '/list', component: ListEnvelop},
    { path: '/test', component: TestTest}
]

const router = createRouter({
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

const app = createApp(App)
app.use(router)
app.use(Layout)
app.use(Button)
app.use(Modal)
app.use(Select)
app.use(Input)
app.use(InputNumber)
app.use(Radio)
app.use(Divider)
app.use(Space)
app.use(Spin)
app.use(Result)
app.use(Tabs)
app.use(Row)
app.use(Col)
app.use(Typography)
app.use(Tooltip)
app.use(Table)
app.use(List)
app.use(Dropdown)
app.use(Menu)
app.use(Tag)
app.config.globalProperties.$message = message;

app.mount('#app')
