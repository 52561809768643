<template>
  <template v-if="isLoading">
    <loading-modal :options="options" @modalCloseEvent="OnModalCloseEvent">
    </loading-modal>
  </template>
</template>

<script>
import { sleep } from "@/utils/utils";
import LoadingModal from "./LoadingModal.vue";
export default {
  components: {
    LoadingModal,
  },
  data: function () {
    return {
      options: {
        isLoading: false,
      },
    };
  },

  created: async function () {
    this.isLoading = true;
    this.options = {
    //   subTitle: "subtitle111",
      // status: ''
    };
    await sleep(3000);
    // this.options = {
    //   title: "Wait for tx confirmed",
    //   subTitle: "success",
    //   status: "success",
    // };
    this.options.status = 'error'
  },
  methods: {
    OnModalCloseEvent: function () {
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
</style>